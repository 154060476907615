<template>
  <div
    class="modal fade"
    id="modalAccountEstimated"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Rincian Piutang</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Akun Perkiraan </label>
              <input
                type="text"
                class="form-control"
                readonly
                placeholder="Masukkan Akun Perkiraan"
                v-model="formModal.nama"
              />
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Nilai </label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">Rp</div>
                </div>
                <input
                  type="text"
                  class="form-control text-right"
                  :readonly="!isEditable"
                  id="inlineFormInputGroupUsername"
                  placeholder="Masukan Nilai"
                  :model-modifiers="{ number: true }"
                  v-money="configMoney"
                  v-model.lazy="formModal.nilai"
                />
              </div>
            </div>

            <div class="form-group d-flex align-items-center">
              <label class="form-label">Keterangan </label>
              <textarea
                rows="3"
                v-model="formModal.keterangan"
                class="form-control"
              ></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              @click="saveDetail"
              :disabled="!isEditable"
              class="btn btn-save"
            >
              Tambah data
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="modalJurnalBack"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Jurnal Kembali</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group">
              <label>Nomor Pembayaran</label>
              <Select2
                v-model="formJurnalKembali.id_pembayaran"
                :options="optionPembayaran"
                placeholder="Pilih akun perkiraan..."
                @change="formChangeJurnal('id_pembayaran', $event)"
                @select="formChangeJurnal('id_pembayaran', $event)"
                :settings="{dropdownParent: '#modalJurnalBack'}"
                :class="{
                  'is-invalid':
                    formErrorJurnal && formErrorJurnal.id_pembayaran,
                }"
              />
              <div
                class="form-error"
                v-if="formErrorJurnal && formErrorJurnal.id_pembayaran"
              >
                {{ formErrorJurnal.id_pembayaran }}
              </div>
            </div>
            <div class="form-group">
              <label>Tanggal :</label>
              <Datepicker
                :autoApply="true"
                :class="{
                  'is-invalid': formErrorJurnal && formErrorJurnal.tanggal,
                }"
                :closeOnScroll="true"
                v-model="formJurnalKembali.tanggal"
                placeholder="Tanggal Pembayaran"
                :format="format"
                :enableTimePicker="false"
                locale="id-ID"
                selectText="Pilih"
                cancelText="Batal"
                @cleared="changeDateJurnal('', 'tanggal')"
                @update:modelValue="changeDateJurnal($event, 'tanggal')"
              ></Datepicker>
            </div>
            <div class="form-group">
              <label class="" for="inlineFormInputGroupUsername"
                >Nominal :</label
              >
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">Rp</div>
                </div>
                <input
                  type="text"
                  v-money="configMoney"
                  v-model.lazy="formJurnalKembali.nilai"
                  :class="{
                    'is-invalid': formErrorJurnal && formErrorJurnal.nilai,
                  }"
                  @keyup="formChangeJurnal('nilai')"
                  class="form-control text-right"
                  placeholder="Nominal Pembayaran"
                />
              </div>
              <div
                class="form-error"
                v-if="formErrorJurnal && formErrorJurnal.nilai"
              >
                {{ formErrorJurnal.nilai }}
              </div>
            </div>
            <div class="form-group">
              <label>Nomor Referensi :</label>
              <input
                type="text"
                :class="{
                  'is-invalid': formErrorJurnal && formErrorJurnal.no_referensi,
                }"
                @keyup="formChangeJurnal('no_referensi')"
                class="form-control"
                v-model="formJurnalKembali.no_referensi"
                placeholder="Masukkan Nomor Referensi"
              />
              <div
                class="form-error"
                v-if="formErrorJurnal && formErrorJurnal.no_referensi"
              >
                {{ formErrorJurnal.no_referensi }}
              </div>
            </div>
            <div class="form-group">
              <label>Keterangan :</label>
              <textarea
                :class="{
                  'is-invalid': formErrorJurnal && formErrorJurnal.keterangan,
                }"
                @keyup="formChangeJurnal('keterangan')"
                class="form-control"
                v-model="formJurnalKembali.keterangan"
                placeholder="Masukkan Keterangan"
              ></textarea>
              <div
                class="form-error"
                v-if="formErrorJurnal && formErrorJurnal.keterangan"
              >
                {{ formErrorJurnal.keterangan }}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              @click="saveJurnalBack"
              :disabled="
                isJurnalback ||
                formData.status_pembayaran == 'cancel' ||
                formData.status_pembayaran == 'unpaid'
              "
              class="btn btn-add"
            >
              <span class="fas fa-spinner fa-spin" v-if="isJurnalback"></span>
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">{{ labelPage }} Piutang Usaha</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-credit">Piutang Usaha </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ labelPage }}
                  </li>
                </ol>
              </nav>
            </div>
            <div
              class="button-edit"
              v-show="validated == 1 && labelPage == 'Edit' && isEditable"
            >
              <button class="btn-add" type="button" @click="editable">
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Edit
              </button>
            </div>
            <div
              v-show="
                (validated != 1 && labelPage == 'Edit') || labelPage == 'Tambah'
              "
            >
              <button
                class="btn btn-save"
                :disabled="isSubmit"
                v-if="isEditable"
              >
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>
          <div class="card info-detail">
            <div class="row">
              <div class="col-12">
                <div class="title">Detail Piutang Usaha</div>
                <div class="sub-title">
                  {{ labelPage }} informasi yang diperlukan
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Nama :</label>
                  <input
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    type="text"
                    class="form-control"
                    placeholder="Masukkan Nama"
                    @keyup="formChange('nama')"
                    v-model="formData.nama"
                    :class="{ 'is-invalid': formError && formError.nama }"
                  />
                  <div class="form-error" v-if="formError && formError.nama">
                    {{ formError.nama }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Client :</label>
                  <Select2
                    v-model="formData.id_client"
                    :options="optionCLient"
                    :disabled="
                      !isEditable || (validated == 1 && labelPage == 'Edit')
                    "
                    :class="{ 'is-invalid': formError && formError.id_client }"
                    placeholder="List Client  "
                    @change="formChange('id_client')"
                    @select="formChange('id_client')"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_client"
                  >
                    {{ formError.id_client }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Akun Piutang</label>
                  <Select2
                    v-model="formData.beban_akun"
                    :disabled="
                      !isEditable || (validated == 1 && labelPage == 'Edit')
                    "
                    :class="{ 'is-invalid': formError && formError.beban_akun }"
                    :options="OptionKasBank"
                    placeholder="List Project"
                    :settings="{ templateResult: formatState }"
                    @change="formChange('beban_akun')"
                    @select="formChange('beban_akun')"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.beban_akun"
                  >
                    {{ formError.beban_akun }}
                  </div>
                </div>
                <div class="form-group">
                  <label>Keterangan</label>
                  <textarea
                    id="keterangan"
                    rows="3"
                    placeholder="Masukan Keterangan"
                    v-model="formData.keterangan"
                    class="form-control"
                    :disabled="
                      !isEditable || (validated == 1 && labelPage == 'Edit')
                    "
                  ></textarea>
                </div>
                <div class="form-group" v-if="formData.id">
                  <label>Umur Piutang</label>
                  <div class="input-group">
                    <input
                      type="text"
                      disabled="true"
                      class="form-control text-right"
                      v-model="umur"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">Hari</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label>Project :</label>
                  <Select2
                    v-model="formData.kode_project"
                    :disabled="
                      !isEditable || (validated == 1 && labelPage == 'Edit')
                    "
                    :options="optionProject"
                    :settings="{ allowClear: true }"
                    :class="{
                      'is-invalid': formError && formError.kode_project,
                    }"
                    placeholder="List Project"
                    @change="formChange('kode_project')"
                    @select="formChange('kode_project')"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.kode_project"
                  >
                    {{ formError.kode_project }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Departemen :</label>
                  <Select2
                    v-model="formData.id_divisi"
                    :disabled="
                      !isEditable || (validated == 1 && labelPage == 'Edit')
                    "
                    :options="optionDepartemen"
                    :settings="{ allowClear: accessDepartement ? false : true }"
                    :class="{ 'is-invalid': formError && formError.id_divisi }"
                    placeholder="List Departemen"
                    @change="formChange('id_divisi')"
                    @select="formChange('id_divisi')"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_divisi"
                  >
                    {{ formError.id_divisi }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Penanggung Jawab Keuangan :</label>
                  <Select2
                    v-model="formData.pic_keuangan"
                    :disabled="
                      !isEditable || (validated == 1 && labelPage == 'Edit')
                    "
                    :options="optionEmployee"
                    :class="{ 'is-invalid': formError && formError.pic_keuangan }"
                    placeholder="List Karyawan"
                    @change="formChange('pic_keuangan')"
                    @select="formChange('pic_keuangan')"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.pic_keuangan"
                  >
                    {{ formError.pic_keuangan }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Tanggal :</label>
                  <Datepicker
                    :disabled="
                      !isEditable || (validated == 1 && labelPage == 'Edit')
                    "
                    :autoApply="true"
                    :closeOnScroll="true"
                    v-model="formData.tanggal"
                    :maxDate="new Date()"
                    placeholder="Tanggal"
                    :format="format"
                    :enableTimePicker="false"
                    locale="id-ID"
                    selectText="Pilih"
                    cancelText="Batal"
                    @update:modelValue="changeDate($event, 'tanggal')"
                  ></Datepicker>
                  <div class="form-error" v-if="formError && formError.tanggal">
                    {{ formError.tanggal }}
                  </div>
                </div>
                <div class="form-group">
                  <label>Jatuh Tempo</label>
                  <Datepicker
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    :autoApply="true"
                    :closeOnScroll="true"
                    v-model="formData.jatuh_tempo"
                    :minDate="formData.tanggal"
                    placeholder="Jatuh Tempo"
                    :format="format"
                    :enableTimePicker="false"
                    locale="id-ID"
                    selectText="Pilih"
                    cancelText="Batal"
                    @update:modelValue="changeDate($event, 'jatuh_tempo')"
                  ></Datepicker>
                  <div
                    class="form-error"
                    v-if="formError && formError.jatuh_tempo"
                  >
                    {{ formError.jatuh_tempo }}
                  </div>
                </div>
                <div class="form-group" v-if="formData.id">
                  <label>Status</label>
                  <div
                    v-if="formData.status_pembayaran == 'paid'"
                    class="green-bedge px-3"
                    style="width: fit-content"
                  >
                    Paid
                  </div>
                  <div
                    v-else
                    class="red-bedge px-3 text-capitalize"
                    style="width: fit-content"
                  >
                    {{ formData.status_pembayaran }}
                  </div>
                </div>
                <!-- <div class="form-group">
                                    <label for="inlineFormInputGroupUsername">Nilai :</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">Rp</div>
                                        </div>
                                        <input type="text" class="form-control text-right" id="inlineFormInputGroupUsername" placeholder="Masukan Nilai Piutang">
                                    </div>
                                </div> -->

                <!-- <div class="form-group">
                                    <label>Jatuh Tempo :</label>
                                    <Datepicker :autoApply="true"  v-model="date" :format="format"></Datepicker>
                                </div> -->
              </div>
            </div>

            <div v-if="!isEdit">
              <div class="row">
                <div class="col-12">
                  <div class="title">Rincian Piutang</div>
                  <div class="sub-title">
                    Masukan rincian yang ada pada Piutang
                  </div>
                </div>
              </div>
              <hr />
              <div class="d-flex justify-content-between">
                <div class="search">
                  <div class="form-group" style="width: 400px">
                    <Select2
                      v-model.lazy="AccountEstimated"
                      :options="OptionAccountEstimated"
                      placeholder="Pilih akun perkiraan..."
                      :settings="{
                        templateResult: formatState,
                      }"
                      @change="formChange('')"
                      @select="mySelectAccountEstimated($event)"
                    />
                  </div>
                </div>
                <div class="card-total">
                  <div class="total-debit d-flex flex-column">
                    <span>Nilai</span>
                    <span class="ml-auto"
                      >Rp {{ formatMoney(formData.nilai) }}</span
                    >
                  </div>
                </div>
              </div>

              <div v-if="formError && formError.nilai">
                <div
                  class="form-error"
                  :style="{ marginBottom: '5px', fontSize: '15px' }"
                >
                  Masukan Terlebih dahulu rincian piutang
                </div>
              </div>

              <div class="table-responsive mt-4">
                <table
                  class="table table-bordered table-striped"
                  id="tableDepartment"
                >
                  <thead>
                    <tr>
                      <th style="width: 15%">Akun</th>
                      <th style="width: 20%">Nama Akun</th>
                      <th style="width: 20%">Nilai</th>
                      <th>Keterangan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in formDetail"
                      :key="index"
                      @click="
                        editDetail(
                          index,
                          value.akun,
                          value.nama,
                          value.nilai,
                          value.keterangan,
                          $event
                        )
                      "
                      :style="{ cursor: 'pointer' }"
                    >
                      <td>{{ value.akun }}</td>
                      <td>{{ value.nama }}</td>
                      <td class="text-right">
                        {{ formatMoney(value.nilai) }}
                      </td>
                      <td class="position-relative" style="padding-right: 40px">
                        {{ value.keterangan ? value.keterangan : "-" }}
                        <img
                          src="/img/avatar/icon-delete.svg"
                          v-if="isEditable"
                          alt=""
                          class="btn-add-row"
                          style="
                            position: absolute;
                            right: 10px;
                            width: 20px;
                            top: 50%;
                            transform: translateY(-50%);
                            cursor: pointer;
                          "
                        />
                      </td>
                    </tr>
                    <tr v-if="formDetail.length === 0">
                      <td colspan="4" class="text-center">
                        No data available in table
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else>
              <div>
                <div class="d-flex justify-content-between align-items-center">
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link active"
                        id="pills-detail-tab"
                        data-toggle="pill"
                        href="#pills-detail"
                        role="tab"
                        aria-controls="pills-detail"
                        aria-selected="true"
                        >Rincian Piutang</a
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="pills-anggota-tab"
                        data-toggle="pill"
                        href="#pills-anggota"
                        role="tab"
                        aria-controls="pills-anggota"
                        aria-selected="false"
                        >Invoice</a
                      >
                    </li>
                    <li
                      class="nav-item"
                      role="presentation"
                      v-if="listPembayaran.length > 0"
                    >
                      <a
                        class="nav-link"
                        id="pills-pembayaran-tab"
                        data-toggle="pill"
                        href="#pills-pembayaran"
                        role="tab"
                        aria-controls="pills-pembayaran"
                        aria-selected="false"
                        >Riwayat Pembayaran</a
                      >
                    </li>
                  </ul>
                  <button
                    type="button"
                    class="btn btn-download"
                    v-show="
                      formData.status_pembayaran != 'unpaid' &&
                      formData.status_pembayaran != 'cancel'
                    "
                    @click="flipJournal"
                  >
                    Jurnal Kembali
                  </button>
                </div>

                <hr style="margin-top: 0px" />
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-detail"
                    role="tabpanel"
                    aria-labelledby="pills-detail-tab"
                  >
                    <div class="d-flex justify-content-between">
                      <div class="search">
                        <div class="form-group" style="width: 400px">
                          <Select2
                            v-model.lazy="AccountEstimated"
                            :disabled="!isEditable"
                            :options="OptionAccountEstimated"
                            placeholder="Pilih akun perkiraan..."
                            :settings="{
                              templateResult: formatState,
                            }"
                            @change="formChange('')"
                            @select="mySelectAccountEstimated($event)"
                          />
                        </div>
                      </div>
                      <div class="card-total">
                        <div class="total-debit d-flex flex-column">
                          <span>Nilai</span>
                          <span class="ml-auto"
                            >Rp {{ formatMoney(formData.nilai) }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <div v-if="formError && formError.nilai">
                      <div
                        class="form-error"
                        :style="{ marginBottom: '5px', fontSize: '15px' }"
                      >
                        Masukan Terlebih dahulu rincian piutang
                      </div>
                    </div>

                    <div class="table-responsive mt-4">
                      <table
                        class="table table-bordered table-striped"
                        id="tableDepartment"
                      >
                        <thead>
                          <tr>
                            <th style="width: 15%">Akun</th>
                            <th style="width: 20%">Nama Akun</th>
                            <th style="width: 20%">Nilai</th>
                            <th>Keterangan</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(value, index) in formDetail"
                            :key="index"
                            @click="
                              editDetail(
                                index,
                                value.akun,
                                value.nama,
                                value.nilai,
                                value.tipe,
                                value.keterangan,
                                $event
                              )
                            "
                            :style="{ cursor: 'pointer' }"
                          >
                            <td>{{ value.akun }}</td>
                            <td>{{ value.nama }}</td>
                            <td class="text-right">
                              {{
                                value.tipe == "debit"
                                  ? "-" + formatMoney(value.nilai)
                                  : formatMoney(value.nilai)
                              }}
                            </td>
                            <td
                              class="position-relative"
                              style="padding-right: 40px"
                            >
                              {{ value.keterangan ? value.keterangan : "-" }}
                              <img
                                src="/img/avatar/icon-delete.svg"
                                v-if="
                                  isEditable &&
                                  validated != 1 &&
                                  labelPage == 'Edit'
                                "
                                alt=""
                                class="btn-add-row"
                                style="
                                  position: absolute;
                                  right: 10px;
                                  width: 20px;
                                  top: 50%;
                                  transform: translateY(-50%);
                                  cursor: pointer;
                                "
                              />
                            </td>
                          </tr>
                          <tr v-if="formDetail.length === 0">
                            <td colspan="4" class="text-center">
                              No data available in table
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="pills-anggota"
                    role="tabpanel"
                    aria-labelledby="pills-anggota-tab"
                  >
                    <div class="d-flex justify-content-end">
                      <div class="card-total">
                        <button
                          class="btn btn-add"
                          v-if="
                            formData.status_pembayaran !== 'paid' &&
                            formData.status_pembayaran !== 'cancel' &&
                            (checkAccess('receivable') ||
                              checkAccess(
                                'receivable',
                                'cru_invoice_receivable'
                              ))
                          "
                          type="button"
                          @click="createInvoice"
                        >
                          Buat Invoice
                        </button>
                        <!-- <button class="btn btn-print-invoice" data-toggle="modal"
                                                    data-target="#modalPrintInvoice">Cetak Invoice</button> -->
                      </div>
                    </div>
                    <div class="table-responsive mt-4">
                      <table
                        class="table table-bordered table-striped table-hover"
                        id="tableDepartment"
                      >
                        <thead>
                          <tr>
                            <th style="width: 15%">Nomor Invoice</th>
                            <th style="width: 20%">Tanggal</th>
                            <th style="width: 20%">Jatuh Tempo</th>
                            <th style="width: 15%">Sub Total</th>
                            <th style="width: 15%">Pajak</th>
                            <th style="width: 20%">Total</th>
                            <th style="width: 10%">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(value, index) in invoiceList"
                            :key="index"
                            v-on:click="detail(value.no_invoice)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Klik untuk melihat detail"
                          >
                            <td>{{ value.no_invoice }}</td>
                            <td>{{ formatDate(value.tanggal) }}</td>
                            <td>{{ formatDate(value.due_date) }}</td>
                            <td class="text-right">{{ formatMoney(value.sub_total) }}</td>
                            <td class="text-right">{{ formatMoney(value.nilai_pajak) }}</td>
                            <td class="text-right">{{ formatMoney(value.nilai) }}</td>
                            <td style="padding: 10px">
                              <div
                                class="green-bedge"
                                v-if="value.status_pembayaran == 'paid'"
                              >
                                Paid
                              </div>
                              <div class="red-bedge text-capitalize" v-else>
                                {{ value.status_pembayaran }}
                              </div>
                            </td>
                          </tr>
                          <tr v-if="invoiceList.length === 0">
                            <td colspan="7" class="text-center">
                              Tidak ada data
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="pills-pembayaran"
                    v-if="listPembayaran.length > 0"
                    role="tabpanel"
                    aria-labelledby="pills-pembayaran-tab"
                  >
                    <div class="table-responsive mt-4">
                      <table
                        class="table table-bordered table-striped table-hover"
                        id="tableDepartment"
                      >
                        <thead>
                          <tr>
                            <th style="width: 15%">Nomor</th>
                            <th style="width: 15%">No. Invoice</th>
                            <th style="width: 18%">Tanggal</th>
                            <th style="width: 15%">Keterangan</th>
                            <th style="width: 15%">No. Referensi</th>
                            <th style="width: 15%">Nominal</th>
                            <th style="width: 15%">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(value, index) in listPembayaran"
                            :key="index"
                          >
                            <td style="word-wrap: anywhere;">{{ value.no_transaksi }}</td>
                            <td style="word-wrap: anywhere;">
                              {{ value.no_invoice ? value.no_invoice : "-" }}
                            </td>
                            <td>{{ formatDate(value.tanggal_pembayaran) }}</td>
                            <td style="word-wrap: anywhere;">{{ value.keterangan ? value.keterangan : '-' }}</td>
                            <td>
                              {{
                                value.no_referensi ? value.no_referensi : "-"
                              }}
                            </td>
                            <td class="text-right">
                              {{ formatMoney(value.nilai) }}
                            </td>
                            <td style="padding: 10px">
                              <div
                                class="green-bedge"
                                v-if="value.tipe == 'pembayaran'"
                              >
                                Paid
                              </div>
                              <div class="orange-bedge" v-else>
                                Jurnal Kembali
                              </div>
                            </td>
                          </tr>
                          <tr v-if="listPembayaran.length == 0">
                            <td colspan="7" class="text-center">
                              Tidak ada data
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="row">
                                <div class="col-12">
                                    <div class="title">Rincian Piutang</div>
                                    <div class="sub-title">Rincian dari piutang usaha yang diperlukan</div>
                                </div>
                            </div>
                            <hr> -->
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
  <InvoiceComponent
    :show="showModalInvoice"
    :noInvoice="NomorInvoice"
    :type="'piutang'"
    @reload="getData()"
    @hide="hideInvoice"
  />
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import InvoiceComponent from "../components/invoiceComponents.vue";
import Select2 from "vue3-select2-component";
import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import { get_ListDivisi } from "../../../actions/master";
import { get_AkunDefault, get_AkunList } from "../../../actions/akun_perkiraan";
import {
  checkRules,
  cksClient,
  showAlert,
  checkModuleAccess,
} from "../../../helper";
import moment from "moment";
import "moment/locale/id";
import { VMoney } from "v-money";
import { maska } from "maska";
import { get_ListClient } from "../../../actions/client";
import { get_ProjectList } from "../../../actions/project";
import { get_KaryawanList } from "../../../actions/karyawan";
import {
  get_PiutangDetail,
  post_PiutangJurnalBack,
  post_PiutangSave,
} from "../../../actions/piutang";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    InvoiceComponent,
    Select2,
    Datepicker,
    // TableLite
  },
  directives: {
    money: VMoney,
    maska,
  },

  data() {
    return {
      validated: 1,
      checkAccess: checkModuleAccess,
      accessDepartement: cksClient().get("_account").akses_departement,
      btnHide: false,
      statusPayment: ["Paid", "Unpaid"], // or [{id: key, text: value}, {id: key, text: value}]
      OptionKasBank: [],
      optionDepartemen: [],
      optionCLient: [],
      optionProject: [],
      optionEmployee: [],
      OptionAccountEstimated: [
        {
          id: 1,
          text: "Kas Kecil Kantor Bantul",
          no: "111.000-0",
          type: "Kas & Bank",
        },

        {
          id: 2,
          text: "Kas Kecil Kantor Jogja",
          no: "111.000-0",
          type: "Kas & Bank",
        },
      ],
      placeholder: [
        {
          text: "kecamatan",
        },
      ],
      isEdit: this.$route.params.id ? true : false,
      labelPage: this.$route.params.id ? "Edit" : "Tambah",
      id_company: cksClient().get("_account").id_company,
      formModal: {
        key: "",
        id: "",
        nama: "",
        nilai: "",
        keterangan: "",
        tipe: "credit",
      },
      id: this.$route.params.id ? this.$route.params.id : "",
      formData: {
        id: this.$route.params.id ? this.$route.params.id : "",
        nama: "",
        id_client: "",
        id_divisi: "",
        tanggal: "",
        id_company: cksClient().get("_account").id_company,
        kode_project: "",
        beban_akun: "",
        nilai: "",
        jatuh_tempo: "",
        id_jurnal: "",
        status: "aktif",
        status_pembayaran: "unpaid",
        tipe: "credit",
        pic_keuangan: "",
        keterangan: "",
      },
      isEditable: true,
      rules: {
        nama: {
          required: true,
        },
        id_client: {
          required: true,
        },
        beban_akun: {
          required: true,
        },
        nilai: {
          required: true,
        },
        jatuh_tempo: {
          required: true,
        },
        pic_keuangan: {
          required: true,
        },
        tanggal: {
          required: true,
        },
      },
      formError: [],
      AccountEstimated: "",
      onModal: false,
      formDetail: [],
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      isSubmit: false,
      invoiceList: [],
      showModalInvoice: false,
      NomorInvoice: "",
      listPembayaran: [],
      umur: null,

      //jurnal kembali
      isJurnalback: false,
      formErrorJurnal: [],
      optionPembayaran: [],
      formJurnalKembali: {
        id_piutang: this.$route.params.id ? this.$route.params.id : "",
        id_pembayaran: "",
        akun_kas: "",
        tanggal: "",
        nilai: "",
        no_referensi: "",
        keterangan: "",
      },
      rulesJurnalKembali: {
        id_pembayaran: {
          required: true,
        },
        tanggal: {
          required: true,
        },
        nilai: {
          required: true,
          min: 1,
          max: 0,
        },
        no_referensi: {
          required: false,
        },
        keterangan: {
          required: false,
        },
      },
    };
  },

  mounted() {
    // $('#tableDepartment').DataTable({
    //     info: false,
    // });
    $(".tab-pane.fade").removeClass("active show");
    if (localStorage.getItem("invoiceTab")) {
      setTimeout(() => {
        $("#pills-anggota-tab").tab("show");
        $("#pills-anggota").addClass("active show");
        localStorage.removeItem("invoiceTab");
      }, 600);
    } else if (localStorage.getItem("pembayaranSet")) {
      setTimeout(() => {
        $("#pills-pembayaran-tab").tab("show");
        $("#pills-pembayaran").addClass("active show");
        localStorage.removeItem("pembayaranSet");
      }, 600);
    } else {
      setTimeout(() => {
        $("#pills-detail-tab").tab("show");
        $("#pills-detail").addClass("active show");
      }, 600);
    }
    this.tooltip();
  },

  unmounted() {
    $("#pills-detail-tab").tab("show");
  },

  created() {
    if (this.labelPage == "Tambah") {
      if (this.accessDepartement) {
        var data = this.accessDepartement.split("|");
        this.formData.id_divisi = data[0];
      }
      this.getDefaultAkun();
    }
    this.getClient(), this.getProject();
    this.getDivisi();
    this.getKasBank(2);
    this.getKasBank();
    this.getKaryawan();
    if (this.id) {
      this.getData();
    }
    if (!this.checkAccess("receivable", "cru_receivable")) {
      this.isEditable = false;
    }
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },
  methods: {
    getKaryawan() {
      var id_divisi =
          this.accessDepartement && this.formData.id_divisi
            ? this.formData.id_divisi
            : "";
        get_KaryawanList(
          {
            id_company: this.id_company,
            search: "aktif",
            id_divisi: id_divisi,
          },
          async (res) => {
            var { list } = res;
            this.optionEmployee = [];
            for (const key in list) {
              if (Object.hasOwnProperty.call(list, key)) {
                this.optionEmployee.push({
                  id: list[key].id,
                  text: list[key].nama,
                });
              }
            }
          }
      );
    },
    getDefaultAkun() {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      var name_id = "akun_piutang_usaha";
      get_AkunDefault(
        {
          id_company: this.id_company,
          name_id: name_id,
          id_divisi: id_divisi,
        },
        (res) => {
          var list = res.list;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              if (key == 0) {
                this.formData.beban_akun = element;
              }
            }
          }
        }
      );
    },
    buttonHide(data) {
      this.btnHide = data;
    },
    editable() {
      this.validated = 2;
    },
    getData() {
      get_PiutangDetail(
        this.id,
        (res) => {
          const { data, jurnal_data, invoice, pembayaran, is_editable } = res;
          this.formData = data;
          this.umur = moment().diff(
            moment(data.created_at).format("YYYY-MM-DD"),
            "days"
          );
          this.formData.jatuh_tempo = data.jatuh_tempo
            ? moment(data.jatuh_tempo).format("YYYY-MM-DD")
            : "";
          delete this.formData.created_at;
          this.formDetail = [];
          for (const key in jurnal_data) {
            if (Object.hasOwnProperty.call(jurnal_data, key)) {
              var element = jurnal_data[key];
              delete element.no_jurnal;
              this.formDetail.push(element);
            }
          }

          this.optionPembayaran = [];
          for (const i in pembayaran) {
            if (Object.hasOwnProperty.call(pembayaran, i)) {
              const el = pembayaran[i];
              if (el.tipe == "pembayaran") {
                this.optionPembayaran.push({
                  id: el.no_transaksi,
                  text: el.no_transaksi,
                  id_akun: el.id_akun,
                  nilai: el.nilai_kembali
                    ? el.nilai - el.nilai_kembali
                    : el.nilai,
                });
              }
            }
          }

          this.invoiceList = invoice;
          this.listPembayaran = pembayaran;
          this.isEditable = is_editable;
        },
        () => {
          showAlert(this.$swal, {
            title: "WARNING!",
            msg: "Data piutang tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              this.$router.push({
                name: "DataCredit",
              });
            },
          });
        }
      );
    },
    createInvoice() {
      this.$router.push({
        name: "CreateInvoiceAccountReceivable",
        params: {
          id: this.id,
        },
      });
    },
    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },
    detail(no_invoice) {
      this.NomorInvoice = no_invoice;
      this.showModalInvoice = true;
      $('[data-toggle="tooltip"]').tooltip("hide");
    },

    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-4" : state.sub_level == 1 ? "pl-3" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },
    //MASTER
    getClient() {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      get_ListClient(
        {
          id_company: this.id_company,
          search: "aktif",
          id_divisi: id_divisi,
        },
        (res) => {
          const { list } = res;
          this.optionCLient = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionCLient.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        }
      );
    },
    getProject() {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      get_ProjectList(
        {
          id_company: this.id_company,
          search: "aktif",
          id_divisi: id_divisi,
        },
        (res) => {
          const { list } = res;
          this.optionProject = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionProject.push({
                id: element.kode,
                text: element.nama,
              });
            }
          }
        }
      );
    },
    formatDate(string) {
      return moment(string).format("DD MMMM YYYY");
    },
    getDivisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        const { list } = res;
        this.optionDepartemen = list;
      });
    },
    getKasBank(tipe = "") {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: tipe,
          id_divisi: id_divisi,
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
          // console.log(data);
          if (tipe == 2 || tipe == 1) {
            this.OptionKasBank = data;
          } else {
            this.OptionAccountEstimated = data;
          }
        }
      );
    },
    changeDate(data, key) {
      this.formData[key] = moment(data).format("YYYY-MM-DD");
      this.formChange(key);
    },
    async formChange(val) {
      if (val == "id_divisi" && this.accessDepartement) {
        this.formDetail = [];
        this.formData.nilai = 0;
        this.formData.beban_akun = "";
        this.formData.kode_project = "";
        this.getClient();
        this.getProject();
      }

      if (Object.hasOwnProperty.call(this.rules, val)) {
        this.rules[val].changed = true;
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },

    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    saveDetail() {
      if (this.formModal.nilai) {
        if (this.formModal.key !== "") {
          this.formDetail[this.formModal.key] = {
            akun: this.formModal.id,
            nama: this.formModal.nama,
            tipe: this.formModal.tipe,
            keterangan: this.formModal.keterangan,
            nilai: this.formModal.nilai.replace(/\./g, ""),
          };
        } else {
          this.formDetail.push({
            akun: this.formModal.id,
            nama: this.formModal.nama,
            tipe: this.formModal.tipe,
            keterangan: this.formModal.keterangan,
            nilai: this.formModal.nilai.replace(/\./g, ""),
          });
        }
        this.countDetail();
        $("#modalAccountEstimated").modal("hide");
      }
    },
    editDetail(key, id, nama, nilai, tipe, keterangan, ev) {
      if (ev.target && ev.target.className === "btn-add-row") {
        this.deleteDetail(key);
      } else {
        this.formModal.key = key;
        this.formModal.id = id;
        this.formModal.nilai = nilai;
        this.formModal.nama = nama;
        this.formModal.tipe = tipe;
        this.formModal.keterangan = keterangan;
        $("#modalAccountEstimated").modal("show");
      }
    },
    deleteDetail(key) {
      delete this.formDetail[key];
      this.formDetail.length -= 1;
      this.countDetail();
    },
    countDetail() {
      var nilai = 0;
      for (const key in this.formDetail) {
        if (Object.hasOwnProperty.call(this.formDetail, key)) {
          const element = this.formDetail[key];
          nilai += parseInt(element.nilai);
        }
      }
      this.formData.nilai = nilai;
      this.formChange();
    },
    mySelectAccountEstimated(ev) {
      if (ev.id) {
        this.onModal = true;
        this.formModal.key = "";
        this.formModal.id = ev.id;
        this.formModal.nilai = "";
        this.formModal.tipe = "credit";
        this.formModal.keterangan = "";
        this.formModal.nama = ev.text;
        $("#modalAccountEstimated").modal("show");
        setTimeout(() => {
          this.AccountEstimated = "";
        }, 500);
      }
    },
    postData() {
      this.isSubmit = true;
      var dataForm = this.formData;
      dataForm.nilai = this.formData.nilai;
      dataForm.jurnal_data = this.formDetail;
      post_PiutangSave(
        dataForm,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code === 201
              ? "Data piutang usaha berhasil dicatat"
              : "Data piutang usaha berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              this.$router.push({
                name: "DataCredit",
              });
            },
          });
        },
        (err) => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "ERROR!",
            msg: err && err.msg && err.msg == "nilai hutang melebihi nilai kontrak project" ? "Total invoice tidak boleh melebihi dari nilai kontrak" : "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.id
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },
    hideInvoice() {
      this.showModalInvoice = false;
    },

    //jurnal kembali
    async formChangeJurnal(val, value = "") {
      if (Object.hasOwnProperty.call(this.rulesJurnalKembali, val)) {
        this.rulesJurnalKembali[val].changed = true;
      }

      if (val == "id_pembayaran") {
        this.rulesJurnalKembali.nilai.max = this.formatMoney(value.nilai);
        this.formJurnalKembali.akun_kas = value.id_akun;
      }

      var check = await checkRules(
        this.rulesJurnalKembali,
        this.formJurnalKembali
      );
      console.log();
      this.formErrorJurnal = check.error;
    },
    changeDateJurnal(data, key) {
      this.formJurnalKembali[key] = moment(data).format("YYYY-MM-DD");
      this.formChangeJurnal(key);
    },
    flipJournal() {
      this.formJurnalKembali.id_pembayaran = "";
      this.formJurnalKembali.tanggal = "";
      this.formJurnalKembali.nilai = "";
      this.formJurnalKembali.keterangan = "";
      this.formJurnalKembali.no_referensi = "";
      $("#modalJurnalBack").modal("show");
      // this.$router.push({
      //   name: "FlipJournalPayble",
      //   params: {
      //     label: "Hutang",
      //   },
      // });
    },
    postJurnalBack() {
      this.isJurnalback = true;
      post_PiutangJurnalBack(
        this.formJurnalKembali,
        () => {
          $("#modalJurnalBack").modal("hide");
          this.isJurnalback = false;
          this.getData();
          setTimeout(() => {
            showAlert(this.$swal, {
              title: "BERHASIL!",
              msg: "Jurnal kembali berhasil dicatat",
            });
          }, 500);
        },
        () => {
          this.isJurnalback = false;
          showAlert(this.$swal, {
            title: "ERROR!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async saveJurnalBack() {
      for (const key in this.rulesJurnalKembali) {
        if (Object.hasOwnProperty.call(this.rulesJurnalKembali, key)) {
          this.rulesJurnalKembali[key].changed = true;
        }
      }
      var check = await checkRules(
        this.rulesJurnalKembali,
        this.formJurnalKembali
      );
      this.formErrorJurnal = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postJurnalBack();
          },
        });
      }
    },
  },
  watch: {
    id: function (newVal) {
      this.formData.id = newVal;
      this.getData();
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.btn-lampiran {
  background-color: #f9ffd7;
  color: #b9b201;
  font-weight: 400;
}

#modalAddPayment .btn-save {
  background-color: transparent !important;
  color: #68caf8 !important;
  border: 1px solid #68caf8 !important;
  font-weight: 400;
  font-size: 15px;
  padding: 10px 0px;
}

#modalAddPayment .btn-save:hover {
  background-color: #68caf8 !important;
  color: #fff !important;
}

.card-detail-invoice {
  background: linear-gradient(103.2deg, #6ec0fe 0%, #4df3dd 100%);
  border-radius: 5px;
  color: #fff;
  padding: 10px 20px;
  line-height: 30px;
}

#modalPrintInvoice .modal-dialog {
  max-width: 1162px;
}

.btn-add-payment {
  background-color: #d7ffea;
  color: #3cb778;
  padding: 5px 10px;
  font-weight: 500;
  margin-right: 15px;
}

.btn-print-invoice {
  background-color: #f9ffd7;
  color: #b9b201;
  padding: 5px 10px;
  font-weight: 500;
}

.address-company {
  font-size: 12px;
  color: #263238;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.noEstimated {
  font-size: 12px;
  color: #898989;
}

.form-label {
  max-width: 134px;
  width: 200px;
}

.card-total {
  display: flex;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.payment-invoice {
  padding: 0px 46px;
}

.sub-total {
  margin-right: 50px;
  font-size: 14px;
}

.amount {
  font-size: 14px;
}

.name-company {
  font-size: 18px;
  font-weight: 500;
}

.detail-company .value {
  color: #263238;
  font-size: 16px;
  font-weight: 400;
}

.detail-company .text {
  color: #cdcdcd;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.title-invoice {
  font-weight: 700;
  font-size: 50px;
  background: linear-gradient(102.18deg, #6ec0fe -17.12%, #61efe7 95.5%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.total-payment {
  font-size: 24px;
  font-weight: 600;
  margin-top: 10px;
}

.status-invoice-paid {
  background: #d7ffea;
  color: #3cb778;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 50px;
  border: none;
  cursor: default;
  border-radius: 5px;
}

.btn-export {
  background: #e4efff;
  color: #68caf8;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
  margin-right: 15px;
}

.modal .btn-print-invoice {
  background: #f9ffd7;
  color: #b9b201;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 23px;
  border-radius: 5px;
  border: none;
}

.btn-invoice {
  background: #fff3c7;
  color: #f8ce3a;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 15px;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}
</style>
